<template>
  <div class="home">
    <h3><PopularScience :text="'同视机'" :gjz="'tongshiji'"></PopularScience></h3>

    <h4 class="text-l">同视机检查表</h4>
    <div class="content">
      <p class="bts">同时视（I级）</p>
      <div class="box">
        <div class="bt-a">画片选择：</div>
        <el-radio-group v-model="tsj">
          <el-radio :label="0">10°</el-radio>
          <el-radio :label="1">8°</el-radio>
          <el-radio :label="2">5°</el-radio>
          <el-radio :label="3">3°</el-radio>
          <el-radio :label="4">1°</el-radio>
        </el-radio-group>
      </div>
    </div>

    <div class="content" style="flex-wrap: nowrap;align-items: flex-start;">
      <p class="bts">主观斜视角</p>
      <div class="box">
        <el-radio-group v-model="zgxsj">
          <el-radio :label="0" border  size="medium">有同时视</el-radio>
          <el-radio :label="1" border  size="medium">无同时视</el-radio>
        </el-radio-group>
        <p class="rhd">融合点：</p>
        <el-input class="sr" size="medium" v-model="postData.CL_RE_SYN_fusion" placeholder="请输入"></el-input>
        <span>°</span>
        <el-radio-group class="padd-1" v-model="zgxsj1">
          <el-radio :label="0" size="medium">右眼抑制</el-radio>
          <el-radio :label="1" size="medium">左眼抑制</el-radio>
          <el-radio :label="2"  size="medium">同侧复像</el-radio>
          <el-radio :label="3"  size="medium">中心抑制</el-radio>
        </el-radio-group>
      </div>
    </div>

    <div class="content">
      <p class="bts">客观斜视角</p>
      <div class="box">
        <div class="bt-a">眼球不动/反光点位于角膜中央：</div>
        <el-input class="sr" size="medium" v-model="postData.CL_RE_SYN_center" placeholder="请输入"></el-input>
        <span>°</span>
      </div>
    </div>

    <div class="content" style="align-items: flex-start;">
      <p class="bts">融合视（II级）</p>
      <div class="box" style="display: block">
        <div class="box">
          <div class="bt-a">画片选择：</div>
          <el-radio-group v-model="rh">
            <el-radio :label="0">10°</el-radio>
            <el-radio :label="1">8°</el-radio>
            <el-radio :label="2">5°</el-radio>
            <el-radio :label="3">3°</el-radio>
            <el-radio :label="4">1°</el-radio>
          </el-radio-group>
        </div>
        <div class="box padd-1">
          <div class="bt-a">发散：</div>
          <el-input class="sr" size="medium" v-model="postData.CL_RE_SYN_diverge" placeholder="请输入"></el-input>
          <span>°</span>
          <div class="bt-a" style="padding-left: 1rem">集合：</div>
          <el-input class="sr" size="medium" v-model="postData.CL_RE_SYN_gather" placeholder="请输入"></el-input>
          <span>°</span>
        </div>

      </div>
    </div>

    <div class="content" style="align-items: flex-start;">
      <p class="bts">立体视（III级）</p>
      <div class="box" style="display: block">
        <div class="box">
          <div class="bt-a">画片选择：</div>
          <el-radio-group v-model="lts">
            <el-radio :label="0">10°</el-radio>
            <el-radio :label="1">8°</el-radio>
            <el-radio :label="2">5°</el-radio>
            <el-radio :label="3">3°</el-radio>
            <el-radio :label="4">1°</el-radio>
          </el-radio-group>
        </div>
        <div class="box padd-1">
          <div class="bt-a">立体视：</div>
          <el-radio-group v-model="lts1">
            <el-radio :label="0">有</el-radio>
            <el-radio :label="1">无</el-radio>
          </el-radio-group>
        </div>
      </div>
    </div>

    <div class="content">
      <p class="bts">Kappa角测量</p>
      <div class="box">
        <div>R(+/-)</div>
        <el-input class="sr" size="medium" v-model="postData.CL_RE_SYN_kappa_R" placeholder="请输入"></el-input>
        <span>°</span>
        <div>Kappa角</div>

        <div style="padding-left: 1rem">L(+/-)</div>
        <el-input class="sr" size="medium" v-model="postData.CL_RE_SYN_kappa_L" placeholder="请输入"></el-input>
        <span>°</span>
        <div>Kappa角</div>
      </div>
    </div>

    <div class="content">
      <p class="bts">备注</p>
      <el-input
          class="bzs"
          type="textarea"
          placeholder="请输入内容"
          v-model="postData.CL_RE_SYN_remarks"
          :autosize="{ minRows: 2, maxRows: 3 }"
          resize="none"
          show-word-limit
      >
      </el-input>
    </div>
  </div>
</template>

<script>


export default {
  name: 'Home',
  data() {
    return {
      dialogVisible: true,
      tsj: '',
      zgxsj: '',
      zgxsj1: '',
      rh: '',
      lts: '',
      lts1: '',
      postData: {}
    };
  },
  watch: {
    postData: {
      handler(n, o) {
        this.$store.commit('eyeTest/upTsj', n)
      },
      deep: true
    },
    tsj: function (n, o) {
      this.postData.CL_RE_SYN_picture1 = this.clVal(n, 5)
    },
    zgxsj: function (n, o) {
      this.postData.CL_RE_SYN_subjectVision = this.clVal(n, 2)
    },
    zgxsj1: function (n, o) {
      this.postData.CL_RE_SYN_drepress = this.clVal(n, 4)
    },
    rh: function (n, o) {
      this.postData.CL_RE_SYN_picture2 = this.clVal(n, 5)
    },
    lts: function (n, o) {
      this.postData.CL_RE_SYN_picture3 = this.clVal(n, 5)
    },
    lts1: function (n, o) {
      this.postData.CL_RE_SYN_cubic = this.clVal(n, 2)
    }
  },
  created() {
    if (!this.$store.state.users.CsUser.CustomerId) {return false}
    this._api.eyeTest.getTsj()
        .then(res => {
          if (res.GetListResult && res.GetListResult.length > 0) {
            this.postData = this._clJson(res.GetListResult[0].S_OP_Json)
            // console.log(this.postData)
            this.tsj = this.cldx(this.postData.CL_RE_SYN_picture1)
            this.zgxsj = this.cldx(this.postData.CL_RE_SYN_subjectVision)
            this.zgxsj1 = this.cldx(this.postData.CL_RE_SYN_drepress)
            this.rh = this.cldx(this.postData.CL_RE_SYN_picture2)
            this.lts = this.cldx(this.postData.CL_RE_SYN_picture3)
            this.lts1 = this.cldx(this.postData.CL_RE_SYN_cubic)
          }
        })
  },
  methods: {
    handleClose(done) {
      this.$confirm('确认关闭？')
              .then(_ => {
                done();
              })
              .catch(_ => {});
    },
    cldx(str) {
      if (!str) return ''
      let arr = str.split(',')
      let val = ''
      for (let i = 0; i < arr.length; i++) {
        if (Number(arr[i])) {
          val = i
          break
        }
      }
      return val
    },
    clVal(val, leg) {
      let arr = Array(leg).fill(0)
      arr[Number(val)] = 1
      return arr.join(',')
    }
  }
}
</script>

<style scoped lang="scss">
  h3{padding-bottom: 0.4rem}
  .content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0.2rem 0;
    .box {
      width: 70%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
    .bts {padding-right: 0.3rem;width: 8em;text-align: left;}
    .bt-a {padding-right: 0.4rem; min-width: 5em;text-align: left;}
    .sr {width: 1.2rem;}
    .rhd {
      padding-left: 0.2rem;
    }
    span {
      padding-left: 0.05rem;
      position: relative;
      top: -0.07rem;
    }
  }
  .padd-1 {padding-top: 0.1rem;}
  .bzs {width: 34vw;}
</style>
